import { createSlice } from '@reduxjs/toolkit'

interface OnboardingState {
  isOpen: boolean
}

const initialState: OnboardingState = {
  isOpen: false,
}

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    openOnboardingVideo: (state) => {
      state.isOpen = true
    },
    closeOnboardingVideo: (state) => {
      state.isOpen = false
    },
  },
})

export const { openOnboardingVideo, closeOnboardingVideo } =
  onboardingSlice.actions

export default onboardingSlice.reducer
