import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'
import { Button, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import type { ReactNode } from 'react'
import React, { useRef, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import type { MobileDrawerProps } from '@src/common/types'
import { openOnboardingVideo } from '@src/features/onBoarding/OnBoardingServices'

import DrawerList from './DrawerList'

const MobileDrawer: React.FC<MobileDrawerProps & { children: ReactNode }> = ({
  open,
  setOpen,
}) => {
  const drawerRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const location = useLocation()
  const { search } = location
  const dispatch = useDispatch()

  const schema = useMemo(() => {
    const params = new URLSearchParams(search)
    return (params.get('schema') as 'company' | 'person') || 'company'
  }, [search])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target as Node)
      ) {
        handleDrawerClose()
      }
    }

    if (open) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open])

  const handleOpenOnboarding = () => {
    setOpen(false)
    dispatch(openOnboardingVideo())
  }

  return (
    <Drawer
      sx={{ minWidth: 260 }}
      anchor="left"
      open={open}
      onClose={handleDrawerClose}
    >
      <Box
        ref={drawerRef}
        sx={{
          width: 300,
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.primary
              : theme.palette.primary.main,
          height: '100%',
        }}
        role="presentation"
      >
        <DrawerList open={open} schema={schema} showHeader={true} />
        {open && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              backgroundColor: theme.palette.background.sidebar,
              position: 'absolute',
              bottom: 0,
              width: '100%',
              ml: 2,
              mb: 2,
            }}
          >
            <Button
              startIcon={<VideoLibraryIcon />}
              variant="text"
              sx={{
                textTransform: 'none',
                fontWeight: 400,
                fontSize: '0.875rem',
                color: theme.palette.common.white,
              }}
              onClick={handleOpenOnboarding}
            >
              Open Onboarding Video
            </Button>
          </Box>
        )}
      </Box>
    </Drawer>
  )
}

export default MobileDrawer
