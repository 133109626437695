// thsi will have more categories and filters in the future, for now it is just a simple menu with a
// few categories and subcategories to show how it works, everything else is commented

import CircleIcon from '@mui/icons-material/Circle'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
// import ExpandLessIcon from '@mui/icons-material/ExpandLess'
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  useTheme,
  useMediaQuery,
  Box,
} from '@mui/material'
import React, { useState, useEffect } from 'react'

interface NonRecursiveMenuProps {
  selectedKey: string | null
  setSelectedKey: (cardType: any) => void
  defaultOpenKey?: string
  lenghtPerCategories?: { [key: string]: number }
  schema?: string
}

const CategoryMenu: React.FC<NonRecursiveMenuProps> = ({
  lenghtPerCategories,
  selectedKey,
  setSelectedKey,
  defaultOpenKey,
  schema,
}) => {
  const [openKeys, setOpenKeys] = useState<{ [key: string]: boolean }>({})
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const xxlScreen = useMediaQuery(theme.breakpoints.down('xxl'))

  useEffect(() => {
    if (defaultOpenKey) {
      setOpenKeys((prev) => ({ ...prev, [defaultOpenKey]: true }))
    }
  }, [defaultOpenKey])

  // const handleToggle = (key: string) => {
  //   setOpenKeys((prev) => ({ ...prev, [key]: !prev[key] }))
  // }

  const handleSelect = (key: string) => {
    setSelectedKey(key)
  }

  return (
    <Box
      sx={{
        minWidth: '260px',
        height: '100%',
        maxHeight: '700px',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '3px',
        },
        '&::-webkit-scrollbar-track': {
          background: theme.palette.background.paper,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor:
            theme.palette.mode === 'light'
              ? theme.palette.primary.light
              : theme.palette.primary.dark,
          borderRadius: '4px',
          border: `2px solid ${theme.palette.background.paper}`,
        },

        // Scrollbar styles for Firefox
        scrollbarWidth: 'thin',
        scrollbarColor:
          theme.palette.mode === 'light'
            ? `${theme.palette.primary.light} ${theme.palette.background.paper}`
            : `${theme.palette.primary.dark} ${theme.palette.background.paper}`,
      }}
    >
      <List
        sx={{
          flex: 1,
        }}
      >
        <ListItem disablePadding>
          <ListItemButton
          // onClick={() => handleToggle('category')}
          >
            {/* <ListItemIcon
              sx={{
                minWidth: isMobile ? '35px' : '45px',
                color:
                  isMobile || xxlScreen
                    ? theme.palette.common.white
                    : theme.palette.text.sidebarText,
              }}
            >
              {openKeys['category'] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemIcon> */}
            <ListItemText
              primary="Categories"
              primaryTypographyProps={{ variant: 'h6' }}
              sx={{
                color:
                  isMobile || xxlScreen
                    ? theme.palette.common.white
                    : 'text.secondary',
              }}
            />
          </ListItemButton>
        </ListItem>
        <Collapse in={openKeys['category']} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* Adverse Media */}
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleSelect('adverse_media')}
                selected={selectedKey === 'adverse_media'}
                sx={{
                  pl: 4,
                  '&.Mui-selected': {
                    backgroundColor: 'rgb(0 0 0 / 9%)',
                    '&:hover': {
                      backgroundColor: 'rgb(0 0 0 / 12%)',
                    },
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: isMobile ? '35px' : '45px',

                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : theme.palette.text.sidebarText,
                  }}
                >
                  {selectedKey === 'adverse_media' ? (
                    <CircleIcon sx={{ ml: 1, fontSize: 18 }} />
                  ) : (
                    <CircleOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Adverse Media"
                  primaryTypographyProps={{ variant: 'body1' }}
                  sx={{
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : 'text.secondary',
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    color: '#4d97ad',
                    fontWeight: 'bold',
                    mr: isMobile ? 2 : xxlScreen ? 2 : 0,
                    textAlign: 'right',
                    flexShrink: 0,
                  }}
                >
                  {lenghtPerCategories?.adverse_media}
                </Typography>
              </ListItemButton>
            </ListItem>
            {/* News */}
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleSelect('basic_news')}
                selected={selectedKey === 'basic_news'}
                sx={{
                  pl: 4,
                  '&.Mui-selected': {
                    backgroundColor: 'rgb(0 0 0 / 9%)',
                    '&:hover': {
                      backgroundColor: 'rgb(0 0 0 / 12%)',
                    },
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: isMobile ? '35px' : '45px',

                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : theme.palette.text.sidebarText,
                  }}
                >
                  {selectedKey === 'basic_news' ? (
                    <CircleIcon sx={{ ml: 1, fontSize: 18 }} />
                  ) : (
                    <CircleOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="News"
                  primaryTypographyProps={{ variant: 'body1' }}
                  sx={{
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : 'text.secondary',
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    color: '#4d97ad',
                    fontWeight: 'bold',
                    mr: isMobile ? 2 : xxlScreen ? 2 : 0,
                    textAlign: 'right',
                    flexShrink: 0,
                  }}
                >
                  {lenghtPerCategories?.basic_news}
                </Typography>
              </ListItemButton>
            </ListItem>
            {/* Company Sources */}
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleSelect('corporate_wealth')}
                selected={selectedKey === 'corporate_wealth'}
                sx={{
                  pl: 4,
                  '&.Mui-selected': {
                    backgroundColor: 'rgb(0 0 0 / 9%)',
                    '&:hover': {
                      backgroundColor: 'rgb(0 0 0 / 12%)',
                    },
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: isMobile ? '35px' : '45px',

                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : theme.palette.text.sidebarText,
                  }}
                >
                  {selectedKey === 'corporate_wealth' ? (
                    <CircleIcon sx={{ ml: 1, fontSize: 18 }} />
                  ) : (
                    <CircleOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Company Sources"
                  primaryTypographyProps={{ variant: 'body1' }}
                  sx={{
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : 'text.secondary',
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    color: '#4d97ad',
                    fontWeight: 'bold',
                    mr: isMobile ? 2 : xxlScreen ? 2 : 0,
                    textAlign: 'right',
                    flexShrink: 0,
                  }}
                >
                  {lenghtPerCategories?.corporate_wealth}
                </Typography>
              </ListItemButton>
            </ListItem>
            {/* Litigation */}
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleSelect('litigation')}
                selected={selectedKey === 'litigation'}
                sx={{
                  pl: 4,
                  '&.Mui-selected': {
                    backgroundColor: 'rgb(0 0 0 / 9%)',
                    '&:hover': {
                      backgroundColor: 'rgb(0 0 0 / 12%)',
                    },
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: isMobile ? '35px' : '45px',
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : theme.palette.text.sidebarText,
                  }}
                >
                  {selectedKey === 'litigation' ? (
                    <CircleIcon sx={{ ml: 1, fontSize: 18 }} />
                  ) : (
                    <CircleOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Litigation"
                  primaryTypographyProps={{ variant: 'body1' }}
                  sx={{
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : 'text.secondary',
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    color: '#4d97ad',
                    fontWeight: 'bold',
                    mr: isMobile ? 2 : xxlScreen ? 2 : 0,
                    textAlign: 'right',
                    flexShrink: 0,
                  }}
                >
                  {lenghtPerCategories?.litigation}
                </Typography>
              </ListItemButton>
            </ListItem>
            {/* Sanctions & Watchlists */}
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleSelect('sanction_watchlist_pep')}
                selected={selectedKey === 'sanction_watchlist_pep'}
                sx={{
                  pl: 4,
                  '&.Mui-selected': {
                    backgroundColor: 'rgb(0 0 0 / 9%)',
                    '&:hover': {
                      backgroundColor: 'rgb(0 0 0 / 12%)',
                    },
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: isMobile ? '35px' : '45px',
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : theme.palette.text.sidebarText,
                  }}
                >
                  {selectedKey === 'sanction_watchlist_pep' ? (
                    <CircleIcon sx={{ ml: 1, fontSize: 18 }} />
                  ) : (
                    <CircleOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Sanctions & Watchlists"
                  primaryTypographyProps={{ variant: 'body1' }}
                  sx={{
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : 'text.secondary',
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    color: '#4d97ad',
                    fontWeight: 'bold',
                    mr: isMobile ? 2 : xxlScreen ? 2 : 0,
                    textAlign: 'right',
                    flexShrink: 0,
                  }}
                >
                  {lenghtPerCategories?.sanction_watchlist_pep}
                </Typography>
              </ListItemButton>
            </ListItem>

            {/* Beneficial Ownership */}
            {schema === 'company' && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleSelect('beneficial_ownership_ro')}
                  selected={selectedKey === 'beneficial_ownership_ro'}
                  sx={{
                    pl: 4,
                    '&.Mui-selected': {
                      backgroundColor: 'rgb(0 0 0 / 9%)',
                      '&:hover': {
                        backgroundColor: 'rgb(0 0 0 / 12%)',
                      },
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: isMobile ? '35px' : '45px',
                      color:
                        isMobile || xxlScreen
                          ? theme.palette.common.white
                          : theme.palette.text.sidebarText,
                    }}
                  >
                    {selectedKey === 'beneficial_ownership_ro' ? (
                      <CircleIcon sx={{ ml: 1, fontSize: 18 }} />
                    ) : (
                      <CircleOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary="Beneficial Ownership"
                    primaryTypographyProps={{ variant: 'body1' }}
                    sx={{
                      color:
                        isMobile || xxlScreen
                          ? theme.palette.common.white
                          : 'text.secondary',
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#4d97ad',
                      fontWeight: 'bold',
                      mr: isMobile ? 2 : xxlScreen ? 2 : 0,
                      textAlign: 'right',
                      flexShrink: 0,
                    }}
                  >
                    {lenghtPerCategories?.beneficial_ownership_ro ||
                      lenghtPerCategories?.open_ownership}
                  </Typography>
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Collapse>

        {/* Within Results */}
        {/* <ListItem disablePadding>
          <ListItemButton onClick={() => handleToggle('within_results')}>
            <ListItemIcon
              sx={{
                minWidth: isMobile ? '35px' : '45px',
                color:
                  isMobile || xxlScreen
                    ? theme.palette.common.white
                    : theme.palette.text.sidebarText,
              }}
            >
              {openKeys['within_results'] ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </ListItemIcon>
            <ListItemText
              primary="Within Results"
              primaryTypographyProps={{ variant: 'h6' }}
              sx={{
                color:
                  isMobile || xxlScreen
                    ? theme.palette.common.white
                    : 'text.secondary',
              }}
            />
          </ListItemButton>
        </ListItem> */}
        {/* <Collapse in={openKeys['within_results']} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
      
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleSelect('subcategory2-1')}
                sx={{ pl: 4 }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: isMobile ? '35px' : '45px',
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : theme.palette.text.sidebarText,
                  }}
                >
                  {selectedKey === 'subcategory2-1' ? (
                    <CircleIcon sx={{ ml: 1, fontSize: 18 }} />
                  ) : (
                    <CircleOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Subcategory 2-1"
                  primaryTypographyProps={{ variant: 'body1' }}
                  sx={{
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : 'text.secondary',
                  }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleSelect('subcategory2-2')}
                sx={{ pl: 4 }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: isMobile ? '35px' : '45px',
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : theme.palette.text.sidebarText,
                  }}
                >
                  {selectedKey === 'subcategory2-2' ? (
                    <CircleIcon sx={{ ml: 1, fontSize: 18 }} />
                  ) : (
                    <CircleOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Subcategory 2-2"
                  primaryTypographyProps={{ variant: 'body1' }}
                  sx={{
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : 'text.secondary',
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse> */}

        {/* Date Range */}
        {/* <ListItem disablePadding>
          <ListItemButton onClick={() => handleToggle('date_range')}>
            <ListItemIcon
              sx={{
                minWidth: isMobile ? '35px' : '45px',
                color:
                  isMobile || xxlScreen
                    ? theme.palette.common.white
                    : theme.palette.text.sidebarText,
              }}
            >
              {openKeys['date_range'] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemIcon>
            <ListItemText
              primary="Date Range"
              primaryTypographyProps={{ variant: 'h6' }}
              sx={{
                color:
                  isMobile || xxlScreen
                    ? theme.palette.common.white
                    : 'text.secondary',
              }}
            />
          </ListItemButton>
        </ListItem> */}
        {/* <Collapse in={openKeys['date_range']} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleSelect('subcategory3-1')}
                sx={{ pl: 4 }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: isMobile ? '35px' : '45px',
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : theme.palette.text.sidebarText,
                  }}
                >
                  {selectedKey === 'subcategory3-1' ? (
                    <CircleIcon sx={{ ml: 1, fontSize: 18 }} />
                  ) : (
                    <CircleOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Subcategory 3-1"
                  primaryTypographyProps={{ variant: 'body1' }}
                  sx={{
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : 'text.secondary',
                  }}
                />
              </ListItemButton>
            </ListItem>
           
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleSelect('subcategory3-2')}
                sx={{ pl: 4 }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: isMobile ? '35px' : '45px',
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : theme.palette.text.sidebarText,
                  }}
                >
                  {selectedKey === 'subcategory3-2' ? (
                    <CircleIcon sx={{ ml: 1, fontSize: 18 }} />
                  ) : (
                    <CircleOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Subcategory 3-2"
                  primaryTypographyProps={{ variant: 'body1' }}
                  sx={{
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : 'text.secondary',
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse> */}

        {/* Company */}
        {/* <ListItem disablePadding>
          <ListItemButton onClick={() => handleToggle('company')}>
            <ListItemIcon
              sx={{
                minWidth: isMobile ? '35px' : '45px',
                color:
                  isMobile || xxlScreen
                    ? theme.palette.common.white
                    : theme.palette.text.sidebarText,
              }}
            >
              {openKeys['company'] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemIcon>
            <ListItemText
              primary="Company"
              primaryTypographyProps={{ variant: 'h6' }}
              sx={{
                color:
                  isMobile || xxlScreen
                    ? theme.palette.common.white
                    : 'text.secondary',
              }}
            />
          </ListItemButton>
        </ListItem> */}
        {/* <Collapse in={openKeys['company']} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleSelect('subcategory4-1')}
                sx={{ pl: 4 }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: isMobile ? '35px' : '45px',
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : theme.palette.text.sidebarText,
                  }}
                >
                  {selectedKey === 'subcategory4-1' ? (
                    <CircleIcon sx={{ ml: 1, fontSize: 18 }} />
                  ) : (
                    <CircleOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Subcategory 4-1"
                  primaryTypographyProps={{ variant: 'body1' }}
                  sx={{
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : 'text.secondary',
                  }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleSelect('subcategory4-2')}
                sx={{ pl: 4 }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: isMobile ? '35px' : '45px',
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : theme.palette.text.sidebarText,
                  }}
                >
                  {selectedKey === 'subcategory4-2' ? (
                    <CircleIcon sx={{ ml: 1, fontSize: 18 }} />
                  ) : (
                    <CircleOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Subcategory 4-2"
                  primaryTypographyProps={{ variant: 'body1' }}
                  sx={{
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : 'text.secondary',
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse> */}

        {/* Source Type */}
        {/* <ListItem disablePadding>
          <ListItemButton onClick={() => handleToggle('source_type')}>
            <ListItemIcon
              sx={{
                minWidth: isMobile ? '35px' : '45px',
                color:
                  isMobile || xxlScreen
                    ? theme.palette.common.white
                    : theme.palette.text.sidebarText,
              }}
            >
              {openKeys['source_type'] ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </ListItemIcon>
            <ListItemText
              primary="Source Type"
              primaryTypographyProps={{ variant: 'h6' }}
              sx={{
                color:
                  isMobile || xxlScreen
                    ? theme.palette.common.white
                    : 'text.secondary',
              }}
            />
          </ListItemButton>
        </ListItem> */}
        {/* <Collapse in={openKeys['source_type']} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
       
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleSelect('subcategory5-1')}
                sx={{ pl: 4 }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: isMobile ? '35px' : '45px',
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : theme.palette.text.sidebarText,
                  }}
                >
                  {selectedKey === 'subcategory5-1' ? (
                    <CircleIcon sx={{ ml: 1, fontSize: 18 }} />
                  ) : (
                    <CircleOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Subcategory 5-1"
                  primaryTypographyProps={{ variant: 'body1' }}
                  sx={{
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : 'text.secondary',
                  }}
                />
              </ListItemButton>
            </ListItem>
        
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleSelect('subcategory5-2')}
                sx={{ pl: 4 }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: isMobile ? '35px' : '45px',
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : theme.palette.text.sidebarText,
                  }}
                >
                  {selectedKey === 'subcategory5-2' ? (
                    <CircleIcon sx={{ ml: 1, fontSize: 18 }} />
                  ) : (
                    <CircleOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Subcategory 5-2"
                  primaryTypographyProps={{ variant: 'body1' }}
                  sx={{
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : 'text.secondary',
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse> */}

        {/* Source */}
        {/* <ListItem disablePadding>
          <ListItemButton onClick={() => handleToggle('source')}>
            <ListItemIcon
              sx={{
                minWidth: isMobile ? '35px' : '45px',
                color:
                  isMobile || xxlScreen
                    ? theme.palette.common.white
                    : theme.palette.text.sidebarText,
              }}
            >
              {openKeys['source'] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemIcon>
            <ListItemText
              primary="Source"
              primaryTypographyProps={{ variant: 'h6' }}
              sx={{
                color:
                  isMobile || xxlScreen
                    ? theme.palette.common.white
                    : 'text.secondary',
              }}
            />
          </ListItemButton>
        </ListItem> */}
        {/* <Collapse in={openKeys['source']} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleSelect('subcategory6-1')}
                sx={{ pl: 4 }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: isMobile ? '35px' : '45px',
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : theme.palette.text.sidebarText,
                  }}
                >
                  {selectedKey === 'subcategory6-1' ? (
                    <CircleIcon sx={{ ml: 1, fontSize: 18 }} />
                  ) : (
                    <CircleOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Subcategory 6-1"
                  primaryTypographyProps={{ variant: 'body1' }}
                  sx={{
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : 'text.secondary',
                  }}
                />
              </ListItemButton>
            </ListItem>
       
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleSelect('subcategory6-2')}
                sx={{ pl: 4 }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: isMobile ? '35px' : '45px',
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : theme.palette.text.sidebarText,
                  }}
                >
                  {selectedKey === 'subcategory6-2' ? (
                    <CircleIcon sx={{ ml: 1, fontSize: 18 }} />
                  ) : (
                    <CircleOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Subcategory 6-2"
                  primaryTypographyProps={{ variant: 'body1' }}
                  sx={{
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : 'text.secondary',
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse> */}

        {/* Language */}
        {/* <ListItem disablePadding>
          <ListItemButton onClick={() => handleToggle('language')}>
            <ListItemIcon
              sx={{
                minWidth: isMobile ? '35px' : '45px',
                color:
                  isMobile || xxlScreen
                    ? theme.palette.common.white
                    : theme.palette.text.sidebarText,
              }}
            >
              {openKeys['language'] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemIcon>
            <ListItemText
              primary="Language"
              primaryTypographyProps={{ variant: 'h6' }}
              sx={{
                color:
                  isMobile || xxlScreen
                    ? theme.palette.common.white
                    : 'text.secondary',
              }}
            />
          </ListItemButton>
        </ListItem> */}
        {/* <Collapse in={openKeys['language']} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
        
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleSelect('subcategory7-1')}
                sx={{ pl: 4 }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: isMobile ? '35px' : '45px',
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : theme.palette.text.sidebarText,
                  }}
                >
                  {selectedKey === 'subcategory7-1' ? (
                    <CircleIcon sx={{ ml: 1, fontSize: 18 }} />
                  ) : (
                    <CircleOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Subcategory 7-1"
                  primaryTypographyProps={{ variant: 'body1' }}
                  sx={{
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : 'text.secondary',
                  }}
                />
              </ListItemButton>
            </ListItem>
        
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleSelect('subcategory7-2')}
                sx={{ pl: 4 }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: isMobile ? '35px' : '45px',
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : theme.palette.text.sidebarText,
                  }}
                >
                  {selectedKey === 'subcategory7-2' ? (
                    <CircleIcon sx={{ ml: 1, fontSize: 18 }} />
                  ) : (
                    <CircleOutlinedIcon sx={{ ml: 1, fontSize: 18 }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Subcategory 7-2"
                  primaryTypographyProps={{ variant: 'body1' }}
                  sx={{
                    color:
                      isMobile || xxlScreen
                        ? theme.palette.common.white
                        : 'text.secondary',
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Collapse> */}
      </List>
    </Box>
  )
}

export default CategoryMenu
