import { useTheme, Box } from '@mui/material'
import type { ReactNode } from 'react'
import { Suspense, lazy, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ConfettiOverlay from '@src/common/components/ConfettiOverlay'
import { useUser } from '@src/common/hooks/useUserContext'
import {
  closeOnboardingVideo,
  openOnboardingVideo,
} from '@src/features/onBoarding/OnBoardingServices'
import MiniDrawer from '@src/layouts/sidebar'
import type { RootState } from '@src/store'
import { capitalizeFirstLetter } from '@src/utils/helpers'

import OnboardingVideo from '../features/onBoarding/OnBordingVideo'

const SubscriptionPlans = lazy(async () => import('@src/features/Subscription'))

const PrivateLayout = ({ children }: { children: ReactNode }) => {
  const theme = useTheme()
  const { isSubscribed, userInfo, subscriptionPlan } = useUser()
  const logins = userInfo?.logins?.count || 0

  const [showConfetti, setShowConfetti] = useState(false)
  const plantType = capitalizeFirstLetter(
    subscriptionPlan?.type || 'enterprise',
  )

  const dispatch = useDispatch()
  const isOpen = useSelector((state: RootState) => state.onboarding.isOpen)

  useEffect(() => {
    if (!userInfo?.email) return

    const onboardingKey = `hasSeenOnboarding_${userInfo.email}`
    const hasSeenOnboarding = localStorage.getItem(onboardingKey) === 'true'

    if (isSubscribed && !hasSeenOnboarding && logins <= 1) {
      dispatch(openOnboardingVideo())
      setShowConfetti(true)

      const timer = setTimeout(() => setShowConfetti(false), 7000)
      return () => clearTimeout(timer)
    }
  }, [isSubscribed, userInfo, dispatch])

  const handleClosePopup = () => {
    dispatch(closeOnboardingVideo())
    setShowConfetti(false)
    if (userInfo?.email) {
      const onboardingKey = `hasSeenOnboarding_${userInfo.email}`
      localStorage.setItem(onboardingKey, 'true')
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <ConfettiOverlay isActive={showConfetti} />
      <OnboardingVideo
        showPopup={isOpen}
        handleClosePopup={handleClosePopup}
        plantType={plantType}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Suspense fallback={<div />}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {!isSubscribed ? (
              <SubscriptionPlans />
            ) : (
              <Suspense fallback={<div />}>
                <MiniDrawer>
                  <Suspense fallback={<div />}>{children}</Suspense>
                </MiniDrawer>
              </Suspense>
            )}
          </Box>
        </Suspense>
      </Box>
    </Box>
  )
}

export default PrivateLayout
