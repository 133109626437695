import React from 'react'
import Confetti from 'react-confetti'
import { useWindowSize } from 'react-use'

const ConfettiOverlay = ({ isActive }: { isActive: boolean }) => {
  const { width, height } = useWindowSize()

  if (!isActive) return null

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 2000,
        pointerEvents: 'none',
      }}
    >
      <Confetti width={width} height={height} />
    </div>
  )
}

export default ConfettiOverlay
