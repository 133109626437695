import {
  useTheme,
  useMediaQuery,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Divider,
} from '@mui/material'
import React from 'react'

interface OnBordingVideoProps {
  showPopup: boolean
  handleClosePopup: () => void
  plantType: string
}

const OnBordingVideo: React.FC<OnBordingVideoProps> = ({
  showPopup,
  handleClosePopup,
  plantType,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      open={showPopup}
      onClose={handleClosePopup}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 4,
          overflow: 'hidden',
          boxShadow: isMobile ? 'none' : theme.shadows[5],
          margin: isMobile ? 1 : 'auto',
          width: isMobile ? '100%' : 'auto',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: isMobile ? '1.4rem' : '1.6rem',
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          py: isMobile ? 1 : 2,
        }}
      >
        Welcome to Your {plantType} Experience!
      </DialogTitle>
      <Divider />
      <DialogContent
        sx={{
          px: isMobile ? 1 : 2,
          py: isMobile ? 1 : 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: isMobile ? 1 : 2,
          }}
        >
          <Typography
            variant="h6"
            textAlign="center"
            sx={{
              color: theme.palette.text.primary,
              fontSize: isMobile ? '1.2rem' : '1.3rem',
            }}
          >
            Welcome aboard! We're excited to have you as part of our community.
          </Typography>
          <Typography
            variant={isMobile ? 'body2' : 'body1'}
            textAlign="center"
            sx={{
              color: theme.palette.text.secondary,
              fontSize: isMobile ? '0.875rem' : '1rem',
            }}
          >
            This short onboarding video will walk you through everything you
            need to know to get started. From key features to tips on making the
            most of your {plantType} subscription, we’ve got you covered!
          </Typography>
          <video
            width="100%"
            height="auto"
            controls
            style={{
              borderRadius: isMobile ? '4px' : '8px',
              boxShadow: theme.shadows[3],
            }}
          >
            <source src="/videos/clarytasintro.webm" type="video/mp4" />
            <track
              default
              kind="captions"
              srcLang="en"
              src="/videos/clarytasintro.webm"
            />
            Your browser does not support the video tag.
          </video>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          justifyContent: 'center',
          pb: isMobile ? 2 : 3,
        }}
      >
        <Button
          onClick={handleClosePopup}
          variant="contained"
          color="primary"
          sx={{
            px: isMobile ? 2 : 4,
            py: isMobile ? 1 : 1.5,
            fontSize: isMobile ? '0.875rem' : '1rem',
          }}
        >
          Let’s Get Started!
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OnBordingVideo
